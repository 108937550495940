import { PlacementReportPercentage, SalariesData } from '@lib/constants';
import React from 'react';

function PlacementReportFormBanner({ children }) {
  return (
    <>
      <div className="flex w-full items-center justify-around rounded-t-lg bg-[#1A1A1A] p-[16px] text-white">
        <div className="flex flex-col items-center justify-center">
          <h4 className="font-satoshi-bold text-[24px] leading-[30px]">3000+</h4>
          <p className="font-satoshi-regular text-[16px] leading-6 text-[#A3A3A3]">Placement</p>
        </div>

        <span className={'separator-dark'} />

        <div className="flex flex-col items-center justify-center">
          {/* <h4 className="font-satoshi-bold text-[24px] leading-[30px]">₹ 33 LPA</h4>
           */}
          <h4 className="font-satoshi-bold text-[24px] leading-[30px]">
            ₹ {SalariesData.highestSalary}
          </h4>
          <p className="font-satoshi-regular text-[16px] leading-6 text-[#A3A3A3]">
            Highest Salary
          </p>
        </div>

        <span className={'separator-dark'} />

        <div className="flex flex-col items-center justify-center">
          <h4 className="font-satoshi-bold text-[24px] leading-[30px]">
            {PlacementReportPercentage}%
          </h4>
          <p className="font-satoshi-regular text-[16px] leading-6 text-[#A3A3A3]">
            Placement Rate
          </p>
        </div>
      </div>

      {children}
    </>
  );
}

export default PlacementReportFormBanner;
