import React, { useEffect, useState } from 'react';
import { HierarchyPrimary, Primary } from 'stories/Button.stories';
import InputField from 'stories/InputField';
import { PhoneField } from 'stories/PhoneField';

const GlobalOTPForm = ({
  formData,
  refId,
  changeHandler,
  onSubmit,
  onResendOtp,
  validateOtp,
  resendDelay: initialResendDelay = 30,
}) => {
  const [resendDelay, setResendDelay] = useState(initialResendDelay);
  const [isResending, setIsResending] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isWhatsappMessageSent, setIsWhatsappMessageSent] = useState(true);
  useEffect(() => {
    if (resendDelay > 0) {
      const timer = setInterval(() => {
        setResendDelay((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [resendDelay]);

  const handleResendOtp = async () => {
    setIsResending(true);
    try {
      await onResendOtp(formData, true);
      setIsWhatsappMessageSent(false);
      setResendDelay(initialResendDelay);
    } catch (error) {
      setErrors({ ...errors, resend: 'Failed to resend OTP' });
    } finally {
      setIsResending(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { phone, otp } = formData;

    setIsSubmitting(true);

    const validationErrors = validateOtp({ phone, otp });

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setIsSubmitting(false);
      return;
    }

    try {
      await onSubmit({ otp, refId });
    } catch (error) {
      setIsSubmitting(false);
      setErrors({
        ...errors,
        submit: error?.message || error?.response?.data?.message || 'Failed to verify OTP',
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-y-[16px]">
      <div className="flex flex-col gap-y-[6px]">
        <PhoneField
          label={'Phone Number'}
          labelStyle={'text-[14px] leading-5 font-satoshi-medium'}
          className="!rounded-lg !border !border-[#A3A3A3] !py-[8px] !px-[12px] !text-[16px] !leading-6"
          value={formData.phone}
          name="phone"
          placeholder="Enter Mobile Number"
          onChange={changeHandler}
          required
          error={errors?.phone}
        />

        <HierarchyPrimary
          label={
            isResending
              ? 'Resending...'
              : resendDelay > 0
              ? `${resendDelay} seconds`
              : 'Resend OTP on SMS'
          }
          type="button"
          size="small"
          primary={false}
          customStyle="text-[#646464] disabled:text-[#646464] self-end text-[12px] font-satoshi-regular leading-[18px]"
          disabled={isResending || resendDelay > 0}
          onClick={handleResendOtp}
        />
      </div>

      <div className="flex flex-col gap-y-[24px]">
        <InputField
          type="text"
          name="otp"
          error={errors?.otp}
          value={formData?.otp}
          onChange={changeHandler}
          placeholder="Enter Your OTP"
          inputStyle="!rounded-lg !border !border-[#A3A3A3] !py-[8px] !px-[12px] !text-[16px] !leading-6 placeholder:text-gray-400"
        />
        <span className="text-xs text-gray-400">
          <span className="text-red">* </span>
          {isWhatsappMessageSent ? 'OTP sent on WhatsApp' : 'OTP sent on SMS'}
        </span>
        <Primary
          type="submit"
          label="Submit OTP"
          size="large"
          customStyle="w-full"
          disabled={isSubmitting}
        />

        {!!(errors?.resend || errors?.submit) && (
          <p className="ml-1 mt-1 text-center text-xs text-red md:text-sm">
            {errors?.resend || errors?.submit}
          </p>
        )}
      </div>
    </form>
  );
};

export default GlobalOTPForm;
