import React, { useState, useEffect, createRef } from 'react';
import styles from './styles.module.css';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { PLAN_TYPE, STUDENT_SIGNUP } from '@lib/constants';
import woolfLogo from '@assets/woolf-new.png';
import { usePreviousRoute } from '@helper/hooks/usePreviousRoute.js';
import Image from 'next/image';
import cn from 'classnames';
import bytesLogo from '@assets/bytes/bytes-logo.svg';
import { getImgUrl } from '@utils/functions/getImgUrl';
import DropDownMenu from '@reuseable/DropDownMenu';
import { NavigationDivRef } from '@components/local/courses/NavigationMenu';
import { useStore } from '@globalStore/storeProvider';

import IITBg from '@assets/partners-bg/new-IIT-Guwahati-bg.webp';
import WoolfBg from '@assets/partners-bg/woolf-bg.webp';
import { Primary } from 'stories/Button.stories';
import { formatTrackingText } from '@utils/utils';
import iitLogo from '@assets/new-iitg-short-logo.png';

export const navbarRef = createRef();

export default function Header({ about, courses, logo, queryString, coursesSummary }) {
  const [isActive, setIsActive] = useState(false);
  const { showHeader } = useStore();
  const [coursesSummaryIdx, setcoursesSummaryIdx] = useState(0);
  const previousRoute = usePreviousRoute();
  const router = useRouter();
  let isBytesPage = false;

  if (router.asPath.startsWith('/bytes')) isBytesPage = true;

  const isDarkMode =
    ['/', '/hire-from-us', '/refer-and-earn', '/placement-statistics', '/success-stories'].includes(
      router.pathname
    ) ||
    router.pathname.startsWith('/courses') ||
    isBytesPage ||
    router.pathname.startsWith('/degrees');

  const trackNavigation = (e) => {
    if (!e.currentTarget) return;
    const data = {
      type: `navbar_${formatTrackingText(e)}_click`,
      trigger: 'navbar',
      previousUrl: previousRoute,
    };
  };

  let showSticky = true;
  if (
    ['/bytes/tutorials/[slug]/[lesson]', '/bytes/articles/[slug]'].some(
      (path) => router.pathname === path
    )
  ) {
    showSticky = false;
  }

  useEffect(() => {
    if (NavigationDivRef.current) {
      showHeader
        ? (NavigationDivRef.current.style.top = '60px')
        : (NavigationDivRef.current.style.top = '0px');
    }
  }, [showHeader, NavigationDivRef?.current]);

  return (
    <div
      ref={navbarRef}
      className={cn(
        // { sticky: showSticky },
        { sticky: true },
        `left-0 right-0 top-0 z-[1111] bg-white shadow-sm ${showHeader && 'top-[4.5rem] md:top-14'}`
      )}
    >
      <div
        className={cn(
          { 'bg-bytes-black-1 !text-white': isDarkMode },
          `hidden h-16 flex-row items-center justify-between bg-white px-4 shadow-md lg:flex`
        )}
      >
        <div className="center cursor-pointer">
          {isBytesPage ? (
            <div className="flex items-center gap-x-6">
              <Link href="/bytes">
                <a className="flex">
                  <Image src={bytesLogo} width={100} height={40} alt="Bytes" />
                </a>
              </Link>
              <CourseDropDown
                courses={courses}
                coursesSummary={coursesSummary}
                coursesSummaryIdx={coursesSummaryIdx}
                setcoursesSummaryIdx={setcoursesSummaryIdx}
                queryString={queryString}
              />
            </div>
          ) : (
            <div className="flex items-center gap-x-6">
              <Link href="/">
                <a className="flex">
                  <Image
                    priority
                    src={isDarkMode ? '/assets/almabetter.png' : getImgUrl(logo)}
                    alt="almabetter"
                    height={40}
                    objectFit="contain"
                    width={150}
                  />
                </a>
              </Link>

              <CourseDropDown
                courses={courses}
                coursesSummary={coursesSummary}
                coursesSummaryIdx={coursesSummaryIdx}
                setcoursesSummaryIdx={setcoursesSummaryIdx}
                queryString={queryString}
              />
            </div>
          )}
        </div>
        <div className="main-nav !z-[115] gap-x-4 lg:flex lg:items-center">
          <ul className="flex items-center gap-x-6">
            {isBytesPage && (
              <DropDownMenu name={'Home'} href={queryString ? `/?${queryString}` : `/`} />
            )}
            <DropDownMenu
              name={about.title}
              href={`/success-stories`}
              menuLinks={about.innerColumn}
            />

            <DropDownMenu
              name="Masterclass"
              href={queryString ? `/events?${queryString}` : `/events`}
            />
            <DropDownMenu
              name="Jobs"
              href={queryString ? `/jobs${queryString}` : '/jobs'}
              badge={'New'}
            />
            <DropDownMenu
              name="Practice"
              href={queryString ? `/bytes?${queryString}` : `/bytes`}
              trackNavigation={trackNavigation}
              menuLinks={[
                {
                  name: 'Tutorials',
                  link: queryString ? `/bytes/tutorials?${queryString}` : `/bytes/tutorials`,
                },
                {
                  name: 'Articles',
                  link: queryString ? `/bytes/articles?${queryString}` : `/bytes/articles`,
                },
                {
                  name: 'Coding Problems',
                  link: queryString
                    ? `https://grow.almabetter.com/data-science/practice/coding-problems${queryString}`
                    : 'https://grow.almabetter.com/data-science/practice/coding-problems',
                },
                {
                  name: 'Quizzes',
                  link: queryString
                    ? `https://grow.almabetter.com/data-science/practice/quiz-assessments${queryString}`
                    : 'https://grow.almabetter.com/data-science/practice/quiz-assessments',
                },
                {
                  name: 'Learning Videos',
                  link: 'https://www.youtube.com/@AlmaBetter/videos',
                },
                {
                  name: 'Online Compilers',
                  link: queryString ? `/online-compiler?${queryString}` : `/online-compiler`,
                },
                {
                  name: 'Cheat Sheet',
                  link: queryString ? `/bytes/cheat-sheet?${queryString}` : `/bytes/cheat-sheet`,
                },
              ]}
              badge={'Free'}
            />
            <DropDownMenu
              name="Hire from us"
              href={queryString ? `/hire-from-us?${queryString}` : `/hire-from-us`}
            />
            {/* <DropDownMenu
              name="Refer & Earn"
              href={queryString ? `/refer-and-earn?${queryString}` : `/refer-and-earn`}
              badge={'New'}
            /> */}
            <DropDownMenu
              name="About Us"
              trackNavigation={trackNavigation}
              menuLinks={[
                {
                  name: 'About Us',
                  link: queryString ? `/about-us?${queryString}` : `/about-us`,
                },
                {
                  name: 'Blog',
                  link: queryString ? `/blogs?${queryString}` : `/blogs`,
                },
                {
                  name: 'In the news',
                  link: queryString ? `/press-highlights${queryString}` : '/press-highlights',
                },
              ]}
            />
          </ul>
          <a href={`${STUDENT_SIGNUP}/?${queryString}`} target="_blank">
            <button
              className={cn(
                { 'bg-red  !text-white': isDarkMode },
                `${styles.header__button} cursor-pointer font-satoshi-medium text-lg font-medium uppercase`
              )}
            >
              Sign In
            </button>
          </a>
        </div>
      </div>
      <MobileMenu
        about={about}
        courses={courses}
        logo={logo}
        queryString={queryString}
        isActive={isActive}
        setIsActive={setIsActive}
        trackNavigation={trackNavigation}
        isBytesPage={isBytesPage}
        coursesSummary={coursesSummary}
        showHeader={showHeader}
      />
    </div>
  );
}

export const MobileMenu = ({
  about,
  courses,
  logo,
  queryString,
  isActive,
  setIsActive,
  trackNavigation,
  isBytesPage,
  coursesSummary,
  showHeader,
}) => {
  const [showCourseMenu, setshowCourseMenu] = useState(false);
  const [activeCourse, setActiveCourse] = useState('Certifications');
  const filteredCourses = coursesSummary.filter((item) => item.type === activeCourse);

  const handleClick = () => {
    setIsActive(!isActive);
  };

  const handleMobileCourseClick = () => {
    setshowCourseMenu(false);
  };
  useEffect(() => {
    if (isActive) {
      document.getElementById('head').classList.add('overflow-hidden');
    } else {
      document.getElementById('head').classList.remove('overflow-hidden');
    }
  }, [isActive]);

  const closeMobileNav = () => {
    setIsActive(false);
  };

  const practiceSubLink = [
    {
      name: 'Tutorials',
      link: queryString ? `/bytes/tutorials?${queryString}` : `/bytes/tutorials`,
    },
    {
      name: 'Articles',
      link: queryString ? `/bytes/articles?${queryString}` : `/bytes/articles`,
    },
    {
      name: 'Coding Problems',
      link: queryString
        ? `https://grow.almabetter.com/data-science/practice/coding-problems${queryString}`
        : 'https://grow.almabetter.com/data-science/practice/coding-problems',
    },
    {
      name: 'Quizzes',
      link: queryString
        ? `https://grow.almabetter.com/data-science/practice/quiz-assessments${queryString}`
        : 'https://grow.almabetter.com/data-science/practice/quiz-assessments',
    },
    {
      name: 'Learning Videos',
      link: 'https://www.youtube.com/@AlmaBetter/videos',
    },
    {
      name: 'Online Compilers',
      link: queryString ? `/online-compiler?${queryString}` : `/online-compiler`,
    },
    {
      name: 'Cheat Sheet',
      link: queryString ? `/bytes/cheat-sheet?${queryString}` : `/bytes/cheat-sheet`,
    },
  ];

  const aboutUs = [
    {
      id: 1,
      title: 'About Us',
      slug: `/about-us`,
    },
    {
      id: 2,
      title: 'Blog',
      slug: `/blogs`,
    },
    {
      id: 3,
      title: 'In the news',
      slug: '/press-highlights',
    },
  ];

  return (
    <div className={cn({ 'bg-bytes-black-1 text-white': isBytesPage }, 'shadow-lg lg:hidden')}>
      <div className={`sticky flex h-16 w-full flex-row items-center justify-between px-4`}>
        <div className="flex items-end gap-2">
          {!isActive ? (
            <MenuIcon className="block h-7 w-7" onClick={handleClick} />
          ) : (
            <XIcon className="block h-7 w-7" onClick={handleClick} />
          )}
          {isBytesPage ? (
            <Link href="/bytes" passHref>
              <a className="flex">
                <Image src={bytesLogo} width={100} height={40} alt="Bytes" />
              </a>
            </Link>
          ) : (
            <Link href="/" passHref>
              <a className="flex">
                <Image
                  src={logo?.data?.attributes?.url}
                  alt="almabetter"
                  height={40}
                  width={160}
                  objectFit="contain"
                />
              </a>
            </Link>
          )}
        </div>

        <div className="ml-2 flex items-center">
          <button
            onClick={() => setshowCourseMenu(true)}
            className={`mr-3 cursor-pointer select-none rounded-md bg-red px-5 py-0.5 font-satoshi-bold capitalize tracking-wide text-white`}
          >
            Courses
          </button>
          <button className={`mr-2 cursor-pointer uppercase text-red`}>
            <a href={STUDENT_SIGNUP} target="_blank">
              <img src="/assets/icons/sign-in.svg" height={35} width={35} alt="sign-in" />
            </a>
          </button>
        </div>
      </div>
      {showCourseMenu && (
        <section className="fixed inset-0 z-50 bg-white">
          <div
            className={cn(
              {
                ' text-black': isBytesPage,
              },
              `sticky  flex h-16 w-full flex-row items-center justify-between border-b border-gray/50 px-4`
            )}
          >
            <p className="mx-4 cursor-pointer text-center  font-satoshi-bold text-[1.2rem] font-bold  tracking-wide ">
              Courses
            </p>
            <XIcon onClick={() => setshowCourseMenu(false)} className="block h-7 w-7" />
          </div>

          <ul className="h-[120vh]  overflow-scroll pb-24 shadow-xl">
            <div className="mt-4 mb-2 flex flex-wrap items-center gap-2 whitespace-normal px-2">
              {coursesSummary.map((item) => (
                <div
                  className={`cursor-pointer whitespace-nowrap rounded-lg border border-red py-2.5 px-2 text-center font-satoshi-medium text-[0.8rem]
                  ${activeCourse === item.type ? 'bg-red text-white' : 'bg-white text-red'} `}
                  key={item.type}
                  onClick={() => {
                    setActiveCourse(item.type);
                  }}
                >
                  {item.type}
                </div>
              ))}
            </div>

            <section>
              {filteredCourses?.[0]?.courses?.slice(0, 1)?.map?.((course, index, arr) => (
                <div key={index} className="ml-3 mt-4 flex flex-col  gap-3 px-2">
                  <div className="flex items-center gap-2">
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.2204 10.4268C11.6671 10.1334 12.2537 10.4534 12.2537 10.9868V11.8468C12.2537 12.6934 11.5937 13.6001 10.8004 13.8668L8.67374 14.5734C8.3004 14.7001 7.69374 14.7001 7.32707 14.5734L5.2004 13.8668C4.4004 13.6001 3.74707 12.6934 3.74707 11.8468V10.9801C3.74707 10.4534 4.33374 10.1334 4.77374 10.4201L6.14707 11.3134C6.67374 11.6668 7.3404 11.8401 8.00707 11.8401C8.67374 11.8401 9.3404 11.6668 9.86707 11.3134L11.2204 10.4268Z"
                        fill="#F00037"
                      />
                      <path
                        d="M13.32 4.30703L9.32668 1.68703C8.60668 1.2137 7.42001 1.2137 6.70001 1.68703L2.68668 4.30703C1.40001 5.14036 1.40001 7.02703 2.68668 7.86703L3.75335 8.56036L6.70001 10.4804C7.42001 10.9537 8.60668 10.9537 9.32668 10.4804L12.2533 8.56036L13.1667 7.96036V10.0004C13.1667 10.2737 13.3933 10.5004 13.6667 10.5004C13.94 10.5004 14.1667 10.2737 14.1667 10.0004V6.72036C14.4333 5.86036 14.16 4.86036 13.32 4.30703Z"
                        fill="#F00037"
                      />
                    </svg>

                    <p className=" font-satoshi-medium text-[0.9rem] text-black/70">
                      {course?.persona}
                    </p>
                  </div>
                  <div className="flex items-center gap-2">
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.33366 10C1.86033 10 0.666992 11.1933 0.666992 12.6667C0.666992 13.1667 0.806992 13.64 1.05366 14.04C1.51366 14.8133 2.36033 15.3333 3.33366 15.3333C4.30699 15.3333 5.15366 14.8133 5.61366 14.04C5.86033 13.64 6.00033 13.1667 6.00033 12.6667C6.00033 11.1933 4.80699 10 3.33366 10ZM4.64699 12.4467L3.22699 13.76C3.13366 13.8467 3.00699 13.8933 2.88699 13.8933C2.76033 13.8933 2.63366 13.8467 2.53366 13.7467L1.87366 13.0867C1.68033 12.8933 1.68033 12.5733 1.87366 12.38C2.06699 12.1867 2.38699 12.1867 2.58033 12.38L2.90033 12.7L3.96699 11.7133C4.16699 11.5267 4.48699 11.54 4.67366 11.74C4.86033 11.94 4.84699 12.26 4.64699 12.4467Z"
                        fill="#F00037"
                      />
                      <path
                        d="M14.333 8.33203H12.6663C11.933 8.33203 11.333 8.93203 11.333 9.66536C11.333 10.3987 11.933 10.9987 12.6663 10.9987H14.333C14.5197 10.9987 14.6663 10.852 14.6663 10.6654V8.66536C14.6663 8.4787 14.5197 8.33203 14.333 8.33203Z"
                        fill="#F00037"
                      />
                      <path
                        d="M11.0198 3.60141C11.2198 3.79474 11.0531 4.09474 10.7731 4.09474L5.25315 4.08807C4.93315 4.08807 4.77315 3.70141 4.99981 3.47474L6.16648 2.30141C7.15315 1.32141 8.74648 1.32141 9.73315 2.30141L10.9931 3.57474C10.9998 3.58141 11.0131 3.59474 11.0198 3.60141Z"
                        fill="#F00037"
                      />
                      <path
                        d="M14.5796 12.4413C14.1729 13.8146 12.9996 14.668 11.3996 14.668H7.06625C6.80625 14.668 6.63958 14.3813 6.74625 14.1413C6.94625 13.6746 7.07292 13.148 7.07292 12.668C7.07292 10.648 5.42625 9.0013 3.40625 9.0013C2.89958 9.0013 2.40625 9.10797 1.95292 9.30797C1.70625 9.41464 1.40625 9.24797 1.40625 8.9813V8.0013C1.40625 6.18797 2.49958 4.9213 4.19958 4.70797C4.36625 4.6813 4.54625 4.66797 4.73292 4.66797H11.3996C11.5729 4.66797 11.7396 4.67464 11.8996 4.7013C13.2463 4.85464 14.2196 5.67464 14.5796 6.89464C14.6463 7.11464 14.4862 7.33464 14.2596 7.33464H12.7329C11.2863 7.33464 10.1396 8.65464 10.4529 10.1546C10.6729 11.248 11.6863 12.0013 12.7996 12.0013H14.2596C14.4929 12.0013 14.6463 12.228 14.5796 12.4413Z"
                        fill="#F00037"
                      />
                    </svg>
                    <p className=" font-satoshi-medium text-[0.9rem] text-black/70">
                      {course?.outcome}
                    </p>
                  </div>
                </div>
              ))}
            </section>
            <div className="mt-8 flex flex-col px-4 py-4">
              {filteredCourses[0]?.courses?.map((course) => {
                return (
                  <div key={course.id}>
                    <div
                      key={course.id}
                      className="relative mb-4 flex w-full flex-col items-start justify-between overflow-hidden rounded-md border border-gray/50 p-4"
                      onClick={() => {
                        handleMobileCourseClick();
                      }}
                    >
                      <div className="absolute inset-0 bg-black bg-opacity-70">
                        {course?.planType !== 'pg_certification' ? (
                          <Image src={WoolfBg} layout="fill" objectFit="cover" />
                        ) : (
                          <Image src={IITBg} layout="fill" objectFit="cover" />
                        )}
                        <div className="z-1 dark-overlay relative h-full w-full"></div>
                      </div>

                      <Link
                        href={`${
                          course?.planType !== PLAN_TYPE.MASTERS_DEGREE ? '/courses/' : '/degrees/'
                        }${course?.slug}${queryString ? `?${queryString}` : ''}`}
                        as={`${
                          course?.planType !== PLAN_TYPE.MASTERS_DEGREE ? '/courses/' : '/degrees/'
                        }${course?.slug}${queryString ? `?${queryString}` : ''}`}
                        passHref
                      >
                        <div>
                          <div className="relative z-10 flex cursor-pointer flex-col items-start justify-between space-y-3 text-left text-white hover:!text-red">
                            <p
                              className={`flex  items-center font-satoshi-bold text-lg leading-5 line-clamp-2`}
                            >
                              {course?.courseName}
                            </p>
                            <div className={`flex items-center gap-4 `}>
                              <p className="flex items-center gap-4 font-satoshi-medium font-satoshi-medium text-sm">
                                {course?.duration}
                              </p>
                              <div className="h-1 w-1 rounded-full bg-gray-1"></div>
                              <p className="flex items-center gap-4 font-satoshi-medium font-satoshi-medium text-sm">
                                Online live classes
                              </p>
                            </div>
                            <div>
                              <p className="font-satoshi-regular text-xs font-light text-gray-2">
                                Accredited By
                              </p>
                              <div className="mt-1 flex flex-wrap items-center gap-1">
                                <div className="flex items-center gap-2 whitespace-nowrap">
                                  {course?.planType !== 'pg_certification' ? (
                                    <Image
                                      src={woolfLogo}
                                      alt="woolf-university"
                                      height={35}
                                      width={165}
                                      objectFit="cover"
                                    />
                                  ) : (
                                    <Image
                                      src={iitLogo}
                                      height={55}
                                      width={150}
                                      objectFit="contain"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                            <Primary
                              size={'small'}
                              label={
                                course?.planType === PLAN_TYPE.UG_COURSE
                                  ? '100% Internship Assurance'
                                  : '100% Job Assurance'
                              }
                            />
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
          </ul>
        </section>
      )}
      {isActive && (
        <div
          style={{
            opacity: 1,
            position: 'relative',
            width: '100%',
            left: '0',
            height: '120vh',
          }}
        >
          <ul
            className={cn(
              { 'bg-bytes-black-1 ': isBytesPage },
              { 'border-t border-gray-light': !isBytesPage },
              'max-h-[110vh] w-full overflow-scroll  bg-white py-6'
            )}
            style={{
              display: isActive ? 'flex' : '',
              flexDirection: isActive ? 'column' : '',
            }}
          >
            <li
              onClick={closeMobileNav}
              className="active mx-4 cursor-pointer pb-2 font-satoshi-bold text-lg font-bold "
            >
              <Link href={'/'} as="/" prefetch={false}>
                Home
              </Link>
            </li>
            <li className="mx-4 pt-1">
              <>
                <Link
                  href={queryString ? `/courses?${queryString}` : `/courses`}
                  as={queryString ? `/courses?${queryString}` : `/courses`}
                  passHref
                >
                  <button
                    onClick={closeMobileNav}
                    className="mb-2 inline-flex items-center justify-between font-satoshi-bold text-xl font-bold  capitalize"
                  >
                    {courses[0].title}
                  </button>
                </Link>
                <div className="sm:hidden">
                  <div className="space-y-1 px-2 pt-2 pb-3" onClick={handleClick}>
                    {coursesSummary
                      .map((_) => _.courses)
                      .flat()
                      .map((course) => {
                        return (
                          <li
                            key={course?.slug}
                            onClick={trackNavigation}
                            className="block rounded-md py-1 pl-3 font-satoshi-medium"
                          >
                            <Link
                              href={`${
                                course?.planType !== PLAN_TYPE.MASTERS_DEGREE
                                  ? '/courses/'
                                  : '/degrees/'
                              }${course.slug}${queryString ? `?${queryString}` : ''}`}
                              as={`${
                                course?.planType !== PLAN_TYPE.MASTERS_DEGREE
                                  ? '/courses/'
                                  : '/degrees/'
                              }${course.slug}${queryString ? `?${queryString}` : ''}`}
                            >
                              {course?.courseName}
                            </Link>
                          </li>
                        );
                      })}
                  </div>
                </div>
              </>
            </li>
            <li className="mx-4 pt-1">
              <>
                <Link
                  href={queryString ? `/bytes?${queryString}` : `/bytes`}
                  as={queryString ? `/bytes?${queryString}` : `/bytes`}
                  passHref
                >
                  <button
                    onClick={closeMobileNav}
                    className="relative mb-2 inline-flex items-center justify-between font-satoshi-bold text-xl font-bold  capitalize"
                  >
                    Practice
                    <p className="ml-2 bg-green-cyan p-0.5 px-1.5 font-satoshi-medium  font-satoshi-medium text-xs font-semibold capitalize leading-4 tracking-wide text-blue-midnight  shadow-md shadow-green-cyan/30">
                      free
                    </p>
                  </button>
                </Link>

                <div className="sm:hidden">
                  <div className="space-y-1 px-2 pt-2 pb-3" onClick={handleClick}>
                    {practiceSubLink.map((item, index) => {
                      if (item.link.startsWith('http')) {
                        return (
                          <li
                            onClick={trackNavigation}
                            key={item.name}
                            className="block rounded-md py-1 pl-3 font-satoshi-medium capitalize"
                          >
                            <a href={item.link} target="_blank" className="w-full">
                              {item.name}
                            </a>
                          </li>
                        );
                      }

                      return (
                        <li
                          onClick={trackNavigation}
                          key={item.name}
                          className="block rounded-md py-1 pl-3 font-satoshi-medium capitalize"
                        >
                          <Link href={item.link} as={item.link}>
                            <a className="w-full">{item.name}</a>
                          </Link>
                        </li>
                      );
                    })}
                  </div>
                </div>
              </>
            </li>
            <li
              onClick={(e) => {
                trackNavigation(e);
                handleClick();
              }}
              className="active mx-4 cursor-pointer pb-2 font-satoshi-bold text-lg font-bold "
            >
              <Link prefetch={false} href={queryString ? `/jobs${queryString}` : `/jobs`}>
                <a className="flex items-center" target="_blank">
                  Jobs
                  <p className="ml-2 bg-green-cyan p-0.5 px-1.5 font-satoshi-medium  font-satoshi-medium text-xs font-semibold capitalize leading-4 tracking-wide text-blue-midnight  shadow-md shadow-green-cyan/30">
                    new
                  </p>
                </a>
              </Link>
            </li>
            <li
              onClick={closeMobileNav}
              className="active mx-4 cursor-pointer pb-2 font-satoshi-bold text-lg font-bold "
            >
              <Link href="/events" prefetch={false}>
                Masterclass
              </Link>
            </li>
            <li className="mx-4 pt-2">
              <>
                <button className="inline-flex items-center justify-between font-satoshi-bold text-xl font-bold  capitalize">
                  {about.title}
                </button>

                <div className="sm:hidden">
                  <ul className="space-y-1 px-2 pt-2 pb-3" onClick={handleClick}>
                    {about.innerColumn.map((item, index) => (
                      <li
                        onClick={trackNavigation}
                        key={item.id}
                        className="block rounded-md py-1 pl-3 "
                      >
                        <Link
                          prefetch={false}
                          href={queryString ? `/${item.slug}?${queryString}` : `/${item.slug}`}
                          as={`/${item.slug}`}
                        >
                          {item.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            </li>
            <li
              onClick={(e) => {
                trackNavigation(e);
                handleClick();
              }}
              className="active mx-4 cursor-pointer pb-2 font-satoshi-bold text-lg font-bold "
            >
              <Link
                prefetch={false}
                href={queryString ? `/hire-from-us?${queryString}` : `/hire-from-us`}
                as="/hire-from-us"
              >
                Hire from us
              </Link>
            </li>
            {/* <li
              onClick={(e) => {
                trackNavigation(e);
                handleClick();
              }}
              className="active mx-4 cursor-pointer pb-2 font-satoshi-bold text-lg font-bold "
            >
              <Link
                prefetch={false}
                href={queryString ? `/refer-and-earn?${queryString}` : `/refer-and-earn`}
                as="/refer-and-earn"
              >
                <a className="flex items-center">
                  Refer & Earn
                  <p className="ml-2 bg-green-cyan p-0.5 px-1.5 font-satoshi-medium  font-satoshi-medium text-xs font-semibold capitalize leading-4 tracking-wide text-blue-midnight  shadow-md shadow-green-cyan/30">
                    new
                  </p>
                </a>
              </Link>
            </li> */}
            <li className="mx-4 pt-2">
              <>
                <button className="inline-flex items-center justify-between font-satoshi-bold text-xl font-bold  capitalize">
                  About Us
                </button>

                <div className="sm:hidden">
                  <ul className="space-y-1 px-2 pt-2 pb-3" onClick={handleClick}>
                    {aboutUs.map((item, index) => (
                      <li
                        onClick={trackNavigation}
                        key={item.id}
                        className="block rounded-md py-1 pl-3 font-satoshi-medium"
                      >
                        <Link
                          prefetch={false}
                          href={queryString ? `/${item.slug}?${queryString}` : `/${item.slug}`}
                          as={`/${item.slug}`}
                        >
                          {item.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            </li>
            <li
              onClick={(e) => {
                trackNavigation(e);
                handleClick();
              }}
              className="mx-4 cursor-pointer py-2 text-lg"
            >
              <a href={'https://discord.gg/7J9QZCAsb4'} target="_blank">
                <img src="/assets/join-discord.svg" alt="join-discord" />
              </a>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

function CourseDropDown({
  courses,
  coursesSummary,
  coursesSummaryIdx,
  setcoursesSummaryIdx,
  queryString,
}) {
  return (
    <DropDownMenu
      name={courses[0].title}
      href={queryString ? `/courses?${queryString}` : `/courses`}
      itemStyle="!whitespace-normal child:p-0"
      textStyle={'text-white hover:text-white !text-base hover:!text-white'}
      className={'!z-[115] rounded-md bg-red px-[8px] py-[6px]'}
    >
      <ul className="relative flex !max-w-fit rounded-md border border-gray/30 bg-white p-4 shadow-xl">
        <div className="relative z-10 flex flex-col items-center">
          {coursesSummary?.map(({ type, courses }, index) => (
            <section
              key={type}
              onMouseEnter={(e) => {
                e.stopPropagation();
                setcoursesSummaryIdx(index);
              }}
              className={`flex  h-fit cursor-pointer p-2`}
            >
              {!!courses?.length && (
                <div
                  className={`flex items-start ${
                    index === coursesSummaryIdx
                      ? 'border-b-2 border-red bg-red/10 text-red'
                      : 'text-gray-400'
                  } py-2  pl-2 text-left `}
                >
                  <p className="w-44 font-satoshi-bold capitalize line-clamp-3">{type}</p>
                </div>
              )}
            </section>
          ))}
        </div>
        <div>
          <section>
            {coursesSummary?.[coursesSummaryIdx]?.courses
              ?.slice(0, 1)
              // .filter((course) => course?.courseType !== COURSE_TYPE.WEB3)
              ?.map?.((course, index) => {
                return (
                  <div key={index} className="mb-3 flex items-center gap-7 px-6">
                    <div className="flex items-center gap-2">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.2204 10.4268C11.6671 10.1334 12.2537 10.4534 12.2537 10.9868V11.8468C12.2537 12.6934 11.5937 13.6001 10.8004 13.8668L8.67374 14.5734C8.3004 14.7001 7.69374 14.7001 7.32707 14.5734L5.2004 13.8668C4.4004 13.6001 3.74707 12.6934 3.74707 11.8468V10.9801C3.74707 10.4534 4.33374 10.1334 4.77374 10.4201L6.14707 11.3134C6.67374 11.6668 7.3404 11.8401 8.00707 11.8401C8.67374 11.8401 9.3404 11.6668 9.86707 11.3134L11.2204 10.4268Z"
                          fill="#F00037"
                        />
                        <path
                          d="M13.32 4.30703L9.32668 1.68703C8.60668 1.2137 7.42001 1.2137 6.70001 1.68703L2.68668 4.30703C1.40001 5.14036 1.40001 7.02703 2.68668 7.86703L3.75335 8.56036L6.70001 10.4804C7.42001 10.9537 8.60668 10.9537 9.32668 10.4804L12.2533 8.56036L13.1667 7.96036V10.0004C13.1667 10.2737 13.3933 10.5004 13.6667 10.5004C13.94 10.5004 14.1667 10.2737 14.1667 10.0004V6.72036C14.4333 5.86036 14.16 4.86036 13.32 4.30703Z"
                          fill="#F00037"
                        />
                      </svg>
                      <p className=" font-satoshi-medium text-sm text-black/70">
                        {course?.persona}
                      </p>
                    </div>
                    <div className="flex items-center gap-2">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.33366 10C1.86033 10 0.666992 11.1933 0.666992 12.6667C0.666992 13.1667 0.806992 13.64 1.05366 14.04C1.51366 14.8133 2.36033 15.3333 3.33366 15.3333C4.30699 15.3333 5.15366 14.8133 5.61366 14.04C5.86033 13.64 6.00033 13.1667 6.00033 12.6667C6.00033 11.1933 4.80699 10 3.33366 10ZM4.64699 12.4467L3.22699 13.76C3.13366 13.8467 3.00699 13.8933 2.88699 13.8933C2.76033 13.8933 2.63366 13.8467 2.53366 13.7467L1.87366 13.0867C1.68033 12.8933 1.68033 12.5733 1.87366 12.38C2.06699 12.1867 2.38699 12.1867 2.58033 12.38L2.90033 12.7L3.96699 11.7133C4.16699 11.5267 4.48699 11.54 4.67366 11.74C4.86033 11.94 4.84699 12.26 4.64699 12.4467Z"
                          fill="#F00037"
                        />
                        <path
                          d="M14.333 8.33203H12.6663C11.933 8.33203 11.333 8.93203 11.333 9.66536C11.333 10.3987 11.933 10.9987 12.6663 10.9987H14.333C14.5197 10.9987 14.6663 10.852 14.6663 10.6654V8.66536C14.6663 8.4787 14.5197 8.33203 14.333 8.33203Z"
                          fill="#F00037"
                        />
                        <path
                          d="M11.0198 3.60141C11.2198 3.79474 11.0531 4.09474 10.7731 4.09474L5.25315 4.08807C4.93315 4.08807 4.77315 3.70141 4.99981 3.47474L6.16648 2.30141C7.15315 1.32141 8.74648 1.32141 9.73315 2.30141L10.9931 3.57474C10.9998 3.58141 11.0131 3.59474 11.0198 3.60141Z"
                          fill="#F00037"
                        />
                        <path
                          d="M14.5796 12.4413C14.1729 13.8146 12.9996 14.668 11.3996 14.668H7.06625C6.80625 14.668 6.63958 14.3813 6.74625 14.1413C6.94625 13.6746 7.07292 13.148 7.07292 12.668C7.07292 10.648 5.42625 9.0013 3.40625 9.0013C2.89958 9.0013 2.40625 9.10797 1.95292 9.30797C1.70625 9.41464 1.40625 9.24797 1.40625 8.9813V8.0013C1.40625 6.18797 2.49958 4.9213 4.19958 4.70797C4.36625 4.6813 4.54625 4.66797 4.73292 4.66797H11.3996C11.5729 4.66797 11.7396 4.67464 11.8996 4.7013C13.2463 4.85464 14.2196 5.67464 14.5796 6.89464C14.6463 7.11464 14.4862 7.33464 14.2596 7.33464H12.7329C11.2863 7.33464 10.1396 8.65464 10.4529 10.1546C10.6729 11.248 11.6863 12.0013 12.7996 12.0013H14.2596C14.4929 12.0013 14.6463 12.228 14.5796 12.4413Z"
                          fill="#F00037"
                        />
                      </svg>
                      <p className=" text-sm text-black/70">{course?.outcome}</p>
                    </div>
                  </div>
                );
              })}
          </section>
          <section className={`flex h-fit border-gray/50`}>
            <div className={'my-auto mx-6 grid w-[40rem] grid-cols-2 gap-3 py-2'}>
              {coursesSummary?.[coursesSummaryIdx]?.courses?.map?.((course) => {
                return (
                  <div
                    key={`${course.courseType}_${course.planType}`}
                    className="relative flex w-full flex-col items-start justify-between overflow-hidden overflow-hidden rounded-md border border-gray/50 p-4"
                  >
                    <div className="absolute inset-0 bg-black bg-opacity-70">
                      {course?.planType !== 'pg_certification' ? (
                        <Image src={WoolfBg} layout="fill" objectFit="cover" />
                      ) : (
                        <Image src={IITBg} layout="fill" objectFit="cover" />
                      )}
                      <div className="z-1 dark-overlay relative h-full w-full"></div>
                    </div>

                    <Link
                      href={`${
                        course?.planType !== PLAN_TYPE.MASTERS_DEGREE ? '/courses/' : '/degrees/'
                      }${course.slug}${queryString ? `?${queryString}` : ''}`}
                      as={`${
                        course?.planType !== PLAN_TYPE.MASTERS_DEGREE ? '/courses/' : '/degrees/'
                      }${course.slug}${queryString ? `?${queryString}` : ''}`}
                      passHref
                    >
                      <a className="relative z-10">
                        <div className="flex cursor-pointer flex-col items-start justify-between space-y-3 text-left text-white hover:!text-red-dark">
                          <p
                            className={`flex  items-center font-satoshi-medium text-[0.9rem] font-medium leading-5 line-clamp-2`}
                          >
                            {course?.courseName}
                          </p>
                          <div className={`flex items-center gap-4 `}>
                            <p className="flex items-center gap-4 font-satoshi-medium font-satoshi-medium text-sm font-medium ">
                              {course?.duration}
                            </p>
                            <div className="h-1 w-1 rounded-full bg-gray-1"></div>
                            <p className="flex items-center gap-4 font-satoshi-medium text-sm font-normal">
                              Online live classes
                            </p>
                          </div>

                          <div>
                            <p className="mb-1 font-satoshi-regular text-xs font-light text-gray-2">
                              Accredited By
                            </p>
                            <div className="flex items-center gap-1">
                              <div className={'flex items-center gap-x-[24px] whitespace-nowrap'}>
                                {course?.planType !== 'pg_certification' ? (
                                  <Image
                                    src={woolfLogo}
                                    alt="woolf-university"
                                    height={30}
                                    width={125}
                                    objectFit="cover"
                                  />
                                ) : (
                                  <Image
                                    src={iitLogo}
                                    height={50}
                                    width={145}
                                    objectFit="contain"
                                  />
                                )}
                              </div>
                            </div>
                          </div>

                          <Primary
                            size={'small'}
                            label={
                              course?.planType === PLAN_TYPE.UG_COURSE
                                ? '100% Internship Assurance'
                                : '100% Job Assurance'
                            }
                          />
                        </div>
                      </a>
                    </Link>
                  </div>
                );
              })}
            </div>
          </section>
        </div>
      </ul>
    </DropDownMenu>
  );
}
